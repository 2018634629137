import React from 'react'
import ChairModelNavBar from './ChairModelNavBar'

const PedicureChairNavBar = () => (
  <>
    <div className="container-fluid px-2 my-4">
      <ChairModelNavBar />
    </div>
  </>
)

export default PedicureChairNavBar
