import React from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import PedicureChairNavBar from '../../components/PedicureChairNavBar'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Our Spa Chairs"
      description="Contego pedicure spa chairs. Select the model you want to see"
      meta={[
        {
          name: 'keywords',
          content:
            'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
        }
      ]}
    />
    <PedicureChairNavBar />
  </Layout>
)

export default IndexPage
